import * as React from "react";
import { List,
    Datagrid,
    TextField,
    EditButton,
    Create,
    BooleanField,
    SimpleForm,
    Edit,
    TextInput,
    BooleanInput,
    Filter,
    Pagination
 } from 'react-admin';
 import Tooltip from '@material-ui/core/Tooltip';


 export const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);


const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ExtensionMaterialList = props => (
    <List title="LPA Extension Material" {...props} filters={<MessageFilter/>} pagination={<PostPagination />}>
        <Datagrid style={{tableLayout: 'fixed', wordWrap:'break-word'}} >
            <TextField source="extension_material" />
            <TextField source="lookup_code" />
            <TextField source="language_code" />
            <BooleanField source="enabled_flag" />
            <EditButton />
        </Datagrid>
    </List>
);


export const extensionMaterialEdit = props => (
    <Edit title="LPA Extension Material Edit" {...props} >
        <SimpleForm>
            <TextInput multiline disabled source="id" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="extension_material" style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="lookup_code" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput source="language_code" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="created_by" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="creation_date" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="last_updated_by" style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="last_updated_date" style ={{width: '50%'}} fullWidth={true} />
            <BooleanInput source="enabled_flag" />
        </SimpleForm>
    </Edit>
);


export const extensionMaterialCreate = props => (
    <Create title="Label Create" {...props}>
        <SimpleForm>
           <TextInput multiline disabled source="id" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="extension_material" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="lookup_code" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput source="language_code" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="created_by" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="creation_date" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="last_updated_by" style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="last_updated_date" style ={{width: '50%'}} fullWidth={true} />
            <BooleanInput source="enabled_flag" />
        </SimpleForm>
    </Create>
);
