import * as React from "react";
import { List,
    Datagrid,
    TextField,
    EditButton,
    Create,
    BooleanField,
    SimpleForm,
    Edit,
    TextInput,
    BooleanInput,
    Filter,
    Pagination,
    SelectInput
 } from 'react-admin';
 import Tooltip from '@material-ui/core/Tooltip';


 export const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);


const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const pressureModuleList = props => (
    <List title="LPA Pressure Module" {...props} filters={<MessageFilter/>} pagination={<PostPagination />}>
        <Datagrid style={{tableLayout: 'fixed', wordWrap:'break-word'}} >
            <TextField source="pressure_module" />
            <TextField source="lookup_code" />
            <TextField source="language_code" />
            <BooleanField source="enabled_flag" />
            <EditButton />
        </Datagrid>
    </List>
);


export const pressureModuleEdit = props => (
    <Edit title="LPA Pressure Module Edit" {...props} >
        <SimpleForm>
            <TextInput multiline disabled source="id" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="pressure_module" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="lookup_code" style ={{width: '50%'}} fullWidth={true}  />
            <SelectInput source="language_code" allowEmpty emptyValue={null}
            choices={[{ id: 'US', name: 'American | English' },{ id: 'ZHS', name: 'Chinese | 中国' },{ id: 'FI', name: 'Finnish | Finnish' },{ id: 'F', name: 'French | Français' },
            { id: 'D', name: 'German | Deutsch' },{ id: 'HU', name: 'Hungarian | Hungarian' },{ id: 'I', name: 'Italian | Italian' }, { id: 'JA', name: 'Japanese | Japanese' },{ id: 'KO', name: 'Korean | 한국어' },
            { id: 'PL', name: 'Polish | Polish' },{ id: 'PTB', name: 'Portuguese | Portuguese' },{ id: 'RU', name: 'Russian | Русский' },{ id: 'SK', name: 'Slovak | Slovak' },{ id: 'ESA', name: 'Spanish | Español' },]}
             style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="created_by" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="creation_date" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="last_updated_by" style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="last_updated_date" style ={{width: '50%'}} fullWidth={true} />
            <BooleanInput source="enabled_flag" />
        </SimpleForm>
    </Edit>
);


export const pressureModuleCreate = props => (
    <Create title="Label Create" {...props}>
        <SimpleForm>
           <TextInput multiline disabled source="id" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="pressure_module" style ={{width: '50%'}} fullWidth={true} />
            <TextInput multiline source="lookup_code" style ={{width: '50%'}} fullWidth={true}  />
            <SelectInput source="language_code" allowEmpty emptyValue={null}
            choices={[{ id: 'US', name: 'American | English' },{ id: 'ZHS', name: 'Chinese | 中国' },{ id: 'FI', name: 'Finnish | Finnish' },{ id: 'F', name: 'French | Français' },
            { id: 'D', name: 'German | Deutsch' },{ id: 'HU', name: 'Hungarian | Hungarian' },{ id: 'I', name: 'Italian | Italian' }, { id: 'JA', name: 'Japanese | Japanese' },{ id: 'KO', name: 'Korean | 한국어' },
            { id: 'PL', name: 'Polish | Polish' },{ id: 'PTB', name: 'Portuguese | Portuguese' },{ id: 'RU', name: 'Russian | Русский' },{ id: 'SK', name: 'Slovak | Slovak' },{ id: 'ESA', name: 'Spanish | Español' },]}
             style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="created_by" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="creation_date" style ={{width: '50%'}} fullWidth={true}  />
            <TextInput disabled source="last_updated_by" style ={{width: '50%'}} fullWidth={true} />
            <TextInput disabled source="last_updated_date" style ={{width: '50%'}} fullWidth={true} />
            <BooleanInput source="enabled_flag" />
        </SimpleForm>
    </Create>
);