import { defaultTheme } from "react-admin";
import {createTheme} from "@material-ui/core/styles";
import merge from "lodash/merge";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary:{
        main: "#00aa7e",
      },
      secondary: {
        main: "#00aa7e",
      },
      typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Whitney SSm A', 'Whitney SSm B', 'Verdana', 'Geneva', 'sans-serif'].join(','),
    },
    overrides: {
      MuiButton: { // override the styles of all instances of this component
          root: { // Name of the rule
              color: 'white', // Some CSS
          },
      },
    },
    },
    overrides:{
    MuiTableRow: {
      head: {
        "& th": {
          fontWeight: 'bold',
          borderBottom: '2px solid black'
        }
      },
    }
  }
  })
);